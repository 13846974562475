import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, ListGroup } from 'react-bootstrap'
import posed from 'react-pose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown, faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'

const Business = ({location}) => {

  const [ openEdi, setOpenEdi ] = useState(false)
  const [ openIot, setOpenIot ] = useState(false)
  const [ openCloud, setOpenCloud ] = useState(false)
  const [ openDevelop, setOpenDevelop ] = useState(false)
  const [ openSupport, setOpenSupport ] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      business: file(relativePath: { eq: "illust/business.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      circle: file(relativePath: { eq: "illust/business-circle.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      edi: file(relativePath: { eq: "icons/internet.svg" }) {
        publicURL
      },
      iot: file(relativePath: { eq: "icons/iot.svg" }) {
        publicURL
      },
      cloud: file(relativePath: { eq: "icons/cloud.svg" }) {
        publicURL
      },
      develop: file(relativePath: { eq: "icons/pc.svg" }) {
        publicURL
      },
      support: file(relativePath: { eq: "icons/maintenance.svg" }) {
        publicURL
      }
    }
  `)

  const props = {
    open: { 
      opacity: 1,
      height: 'auto',
      transition: {
        ease: 'easeOut',
        duration: 300
      }
    },
    closed: { 
      opacity: 0,
      height: 0,
    }
  }

  const Box = posed.div(props)

  const Edi = () => (
    <div>
      <p>
        EDIシステムは受発注情報や請求書発⾏など、紙や電話でやり取りをしていた情報を電⼦化しインターネットを通して企業間で交換するシステムです。<br className="d-lg-block d-none" />
        現在、企業の⽣産性をUPさせる⼿段の1つとして注⽬されています。<br className="d-lg-block d-none" />
        インボイス制度の導⼊による会計業務の負荷増⼤、労働⼈⼝と⾮労働⼈⼝の逆転による⼈⼿不⾜など、<br className="d-lg-block d-none" />
        社会情勢が⽬まぐるしく変化しており、企業もスピーディーに対応していく必要があります。<br className="d-lg-block d-none" />
        設⽴以来、主要事業として取り組んでいるEDIを通して、刻々と変化する社会情勢に対応するためのソリューションをご提供致します。 <br className="d-lg-block d-none" />
      </p>
      <div className="shadow-box my-5">
        <h2>開発実績</h2>
        <ListGroup variant="flush">
          <ListGroup.Item>
            ⾃動⾞製造業グループ向け汎⽤商流EDI︓VAN型
          </ListGroup.Item>
          <ListGroup.Item>
            自動車部品製造業グループ向け共通EDI︓カンバン対応納⼊指⽰EDI クラウドサーバー連携型
          </ListGroup.Item>
          <ListGroup.Item>
            自動車部品製造業海外グループ向け国際EDI︓単価付発注・納⼊依頼等 クラウドサーバー型
          </ListGroup.Item>
          <ListGroup.Item>
            ⾃動⾞部品製造業グループ向け共通EDI︓⽣産計画・⽣産指⽰他 クラウドサーバー連携型
          </ListGroup.Item>
          <ListGroup.Item>
            農協向けJA間取引EDI
          </ListGroup.Item>
          <ListGroup.Item>
            中⼩企業共通EDI準拠クラウド型EDI EcoChange/EcoChange Lite︓⾒積・注⽂・納品・請求・⽀払対応 クラウド型
            <Row>
              <Col lg="auto">【導⼊実績】</Col>
              <Col>
                航空機製造業・航空機部品製造業・電設資材製造業・電設資材商社・⾃動⾞部品製造業<br className="d-lg-block d-none" />
                ⾦属部品製造業・プラスチック部品製造業・半導体・電⼦部品製造業 など 
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            Mobile EcoChange︓モバイル発注 QR・画像読取 クラウド型
            <Row>
              <Col lg="auto">【導⼊実績】</Col>
              <Col>事務機器販売業・惣菜加⼯⼩売業 など</Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  )

  const EdiWrapper = () => (
    <Container expand="lg">
      <Row className="business-category align-items-center">
        <Col lg>
          <img src={data.edi.publicURL} alt="EDI" className="mr-3"/>
          <h2 className="mr-2">EDI</h2>
          <p className="text-muted">Electronic Data Interchange</p>
        </Col>
        <Col lg="auto" 
          onClick={()=>setOpenEdi(!openEdi)} 
          style={{cursor: 'pointer'}} className="text-right"
        >
          <p>{ openEdi ? '詳細を閉じる' : '詳細を開く' }</p>
          <FontAwesomeIcon
            className="ml-2"
            size="lg"
            icon={ openEdi ? faArrowCircleUp : faArrowCircleDown }  
          />
        </Col>
      </Row>
      <Box pose={openEdi ? 'open' : 'closed'}>
        <Edi />
      </Box>
      <hr className="dots-hr mt-3" />
    </Container>
  )

  const Iot = () => (
    <div>
      <p>
        機械、⼯場、ロボット、⼈などを、インターネットでつなぎ、センサーやIoTデバイスで収集した情報を分析、活⽤するシステムを構築します。<br className="d-lg-block d-none" />
        【情報の収集】【収集した情報の蓄積】【蓄積した情報の分析・活⽤】まで、IoTを有効活⽤するために必要なこと全てをご提案からご⽀援致します。
      </p>
      <div className="shadow-box my-5">
        <h2>開発実績</h2>
        <ListGroup variant="flush">
          <ListGroup.Item>
            GW-MCM System 製造業向けIoTソリューション 稼働実績の可視化 電⼦あんどん対応 ⽣産管理 クラウド/オンプレ
            <Row>
              <Col lg="auto">【導⼊実績】</Col>
              <Col>
                ⾃動⾞部品製造業・⾃動⾞樹脂成形部品製造業・プラスチック部品製造業・⾷品製造業・建設機械製造業 など
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            ⾃動⾞樹脂成形部品製造業 電⼦あんどんシステム
          </ListGroup.Item>
          <ListGroup.Item>
            ⾃動⾞部品製造業 設備異常検知システム
          </ListGroup.Item>
          <ListGroup.Item>
            自動車部品製造業 はんだ槽こて先温度/使⽤回数検知システム
          </ListGroup.Item>
          <ListGroup.Item>
            先端熱技術総合エンジニアリング 設備リモートメンテナンスシステム 
          </ListGroup.Item>
          <ListGroup.Item>
            ⾃動⾞部品製造業 従業員健康状況管理システム
          </ListGroup.Item>
          <ListGroup.Item>
            フィットネスクラブ ⼼拍数可視化システム
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  )

  const IotWrapper = () => (
    <Container expand="lg">
      <Row className="business-category align-items-center">
        <Col lg>
          <img src={data.iot.publicURL} alt="IoT" className="mr-3"/>
          <h2 className="mr-2">IoT</h2>
          <p className="text-muted">Internet of Things</p>
        </Col>
        <Col lg="auto" 
          onClick={()=>setOpenIot(!openIot)} 
          style={{cursor: 'pointer'}} className="text-right"
        >
          <p>{ openIot ? '詳細を閉じる' : '詳細を開く' }</p>
          <FontAwesomeIcon
            className="ml-2"
            size="lg"
            icon={ openIot ? faArrowCircleUp : faArrowCircleDown }  
          />
        </Col>
      </Row>
      <Box pose={openIot ? 'open' : 'closed'}>
        <Iot />
      </Box>
      <hr className="dots-hr mt-2 mb-5" />
    </Container>
  )

  const Cloud = () => (
    <div>
      <p>
        【システム導⼊における初期コストの削減】【システム構築期間の⼤幅削減】など従来のオンプレミスでのシステム構築における課題を解決することができます。<br className="d-lg-block d-none" />
        当社はAWSのコンサルティングパートナーとして、お客様の課題解決をご⽀援致します。
      </p>
      <div className="shadow-box my-5">
        <h2>開発実績</h2>
        <ListGroup variant="flush">
          <ListGroup.Item>
          電⼒業 電⼒需給最適化システム
          </ListGroup.Item>
          <ListGroup.Item>
          不動産業 営業⽀援システム
          </ListGroup.Item>
          <ListGroup.Item>
          中⼩企業共通EDI準拠クラウド型EDI EcoChange/EcoChange Lite/Mobile EcoChange
          </ListGroup.Item>
          <ListGroup.Item>
          ⾃動⾞製造業海外グループ 国際EDI 
          </ListGroup.Item>
          <ListGroup.Item>
          ⾃動⾞樹脂成形部品製造業 電⼦あんどんシステム
          </ListGroup.Item>
          <ListGroup.Item>
          ⾃動⾞部品製造業 設備異常検知システム
          </ListGroup.Item>
          <ListGroup.Item>
          自動車部品製造業 はんだ槽こて先温度/使⽤回数検知システム
          </ListGroup.Item>
          <ListGroup.Item>
          先端熱技術総合エンジニアリング 設備リモートメンテナンスシステム
          </ListGroup.Item>
          <ListGroup.Item>
          ⾃動⾞部品製造業 従業員健康状況管理システム
          </ListGroup.Item>
          <ListGroup.Item>
          フィットネスクラブ ⼼拍数可視化システム
          </ListGroup.Item>
          <ListGroup.Item>
          医療ガス販売業 販売管理システム
          </ListGroup.Item>
          <ListGroup.Item>
          外国⼈向け不動産業 会計システムクラウド化
          </ListGroup.Item>
          <ListGroup.Item>
          外国⼈向け不動産業 固定資産管理システムクラウド化
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  )

  const CloudWrapper = () => (
    <Container expand="lg">
      <Row className="business-category align-items-center">
        <Col lg>
          <img src={data.cloud.publicURL} alt="IoT" className="mr-3"/>
          <h2 className="mr-2">Cloud</h2>
          <p className="text-muted">The Cloud Solutions</p>
        </Col>
        <Col lg="auto" 
          onClick={()=>setOpenCloud(!openCloud)} 
          style={{cursor: 'pointer'}} className="text-right"
        >
          <p>{ openCloud ? '詳細を閉じる' : '詳細を開く' }</p>
          <FontAwesomeIcon
            className="ml-2"
            size="lg"
            icon={ openCloud ? faArrowCircleUp : faArrowCircleDown }  
          />
        </Col>
      </Row>
      <Box pose={openCloud ? 'open' : 'closed'}>
        <Cloud />
      </Box>
      <hr className="dots-hr mt-2 mb-5" />
    </Container>
  )

  const Develop = () => (
    <div>
      <p>
        設⽴以来、独⽴系Slerとして⾃社内で各種システムを開発して参りました。お客様の業界も自動車部品製造業、食品製造業、電⼒、物流、⾦融など多岐に渡ります。<br className="d-lg-block d-none" />
        これまで培ってきたノウハウを活かし、お客様の課題・ご要望に対し、最適なソリューションをご提供致します。
      </p>
      <div className="shadow-box my-5">
        <h2>開発実績</h2>
        <ListGroup variant="flush">
          <ListGroup.Item>
          ⾃動⾞部品製造業 モバイル対応⽣産指⽰・実績収集システム
          </ListGroup.Item>
          <ListGroup.Item>
          ⾃動⾞部品製造業 ⼊荷出荷検品管理システム
          </ListGroup.Item>
          <ListGroup.Item>
          ⾃動⾞部品製造業 トレーサビリティーシステム
          </ListGroup.Item>
          <ListGroup.Item>
          ⾃動⾞部品製造業 ⼯程内⾃動倉庫物流管理システム
          </ListGroup.Item>
          <ListGroup.Item>
          医学会 学会論⽂電⼦化発表システム
          </ListGroup.Item>
          <ListGroup.Item>
          医療学会 学会管理システム
          </ListGroup.Item>
          <ListGroup.Item>
          証券業 証券売買監査システム
          </ListGroup.Item>
          <ListGroup.Item>
          ビジネスプロセスアウトソーシング業 基幹システム
          </ListGroup.Item>
          <ListGroup.Item>
          建築業 外壁劣化チェックシステム
          </ListGroup.Item>
          <ListGroup.Item>
          ⼩売業 POSシステム
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  )

  const DevelopWrapper = () => (
    <Container expand="lg">
      <Row className="business-category align-items-center">
        <Col lg>
          <img src={data.develop.publicURL} alt="IoT" className="mr-3"/>
          <h2 className="mr-2">システム開発</h2>
          <p className="text-muted">System Development</p>
        </Col>
        <Col lg="auto" 
          onClick={()=>setOpenDevelop(!openDevelop)} 
          style={{cursor: 'pointer'}} className="text-right"
        >
          <p>{ openDevelop ? '詳細を閉じる' : '詳細を開く' }</p>
          <FontAwesomeIcon
            className="ml-2"
            size="lg"
            icon={ openDevelop ? faArrowCircleUp : faArrowCircleDown }  
          />
        </Col>
      </Row>
      <Box pose={openDevelop ? 'open' : 'closed'}>
        <Develop />
      </Box>
      <hr className="dots-hr mt-2 mb-5" />
    </Container>
  )

  const Support = () => (
    <div>
      <p>
        【当社が開発し納⼊したシステムの運⽤保守】【⾃社パッケージの保守・Q&Aなどの運⽤⽀援】【納⼊したハードウェアの1次窓⼝】など<br className="d-lg-block d-none" />
        システムやハードウェアの運⽤保守に関する課題解決のご⽀援を⾏っています。<br className="d-lg-block d-none" />
        「システム運⽤やインフラ、ハードウェアの管理に回すリソースがない」などお悩みがございましたら是非ご相談下さい。 
      </p>
      <div className="shadow-box my-5">
        <h2>開発実績</h2>
        <ListGroup variant="flush">
          <ListGroup.Item>
          ⾃動⾞製造業グループ EDIシステム 運⽤⽀援
          </ListGroup.Item>
          <ListGroup.Item>
          電⼒業 電⼒需給最適化システム 運⽤保守
          </ListGroup.Item>
          <ListGroup.Item>
          ⾃動⾞部品製造業 共通EDI 運⽤保守
          </ListGroup.Item>
          <ListGroup.Item>
          製薬業界 ヘルプデスク 
          </ListGroup.Item>
          <ListGroup.Item>
          技術系⼈材派遣業 ヘルプデスク
          </ListGroup.Item>
          <ListGroup.Item>
          技術系⼈材派遣業 キッティングサービス
          </ListGroup.Item>
          <ListGroup.Item>
          ビジネスプロセスアウトソーシング業 ヘルプデスク
          </ListGroup.Item>
          <ListGroup.Item>
          ビジネスプロセスアウトソーシング業 基幹システム保守
          </ListGroup.Item>
          <ListGroup.Item>
          外国⼈向け不動産業 クラウドサーバー保守
          </ListGroup.Item>
          <ListGroup.Item>
          事務系⼈材派遣・紹介業 ヘルプデスク
          </ListGroup.Item>
          <ListGroup.Item>
          ⾃動⾞部品製造業 基幹システム保守
          </ListGroup.Item>
          <ListGroup.Item>
          ⾃動⾞部品製造業 ヘルプデスク
          </ListGroup.Item>
          <ListGroup.Item>
          住宅⽤エネルギー機器販売・施⼯業 クラウドサーバー保守
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  )

  const SupportWrapper = () => (
    <Container expand="lg">
      <Row className="business-category align-items-center">
        <Col lg>
          <img src={data.support.publicURL} alt="保守運用" className="mr-3"/>
          <h2 className="mr-2">運⽤保守</h2>
          <p className="text-muted">Operation and Maintenance</p>
        </Col>
        <Col lg="auto" 
          onClick={()=>setOpenSupport(!openSupport)} 
          style={{cursor: 'pointer'}} className="text-right"
        >
          <p>{ openSupport ? '詳細を閉じる' : '詳細を開く' }</p>
          <FontAwesomeIcon
            className="ml-2"
            size="lg"
            icon={ openSupport ? faArrowCircleUp : faArrowCircleDown }  
          />
        </Col>
      </Row>
      <Box pose={openSupport ? 'open' : 'closed'}>
        <Support />
      </Box>
      <hr className="dots-hr mt-2 mb-5" />
    </Container>
  )

  return(
    <Layout location={location} title="事業紹介" >
      <SEO title="事業紹介" description="株式会社グローバルワイズの事業内容についてご紹介します。" />

      <div className="page-head">
        <Container expand="lg">
          <BackgroundImage fluid={data.business.childImageSharp.fluid} id="page-head-image" >
            <h2>事業紹介</h2>
            <p>
              <br className="d-lg-block d-none" />
              e-EDMソリューションをもってお客様のビジネスモデルをより⾶躍させ、<br className="d-lg-block d-none" />
              収益構造そのものを創造することを⽬的とした会社です。<br className="d-lg-block d-none" />
              e-EDM(Extra-Enterprise Data Management︓⾼度企業間情報管理)とは、<br className="d-lg-block d-none" />
              様々な情報を、部⾨・社内・取引先にて、シェア・連携・管理・活⽤する仕組みを指します。
            </p>
            <div className="text-lg-left text-center">
              <Img fluid={data.circle.childImageSharp.fluid} className="mt-5 mx-lg-0 mx-auto" alt="事業紹介"/>
            </div>
          </BackgroundImage>
        </Container>
        <div className="bottom-border" />
      </div>

      <EdiWrapper />
      <IotWrapper />
      <CloudWrapper />
      <DevelopWrapper />
      <SupportWrapper />
    </Layout>
  )
}

export default Business